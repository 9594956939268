<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <div class="menu-box">
        <h2>新增盘点</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-form
            class="table-form"
            ref="checkFormRef"
            :model="checkForm"
            :rules="checkRules"
          >
            <el-row style="margin-right: 30px">
              <el-col :span="8">
                <el-form-item
                  label="盘点仓库"
                  prop="warehouse_id"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="checkForm.warehouse_id"
                    style="width: 100%"
                    placeholder="请选择盘点仓库"
                    clearable
                  >
                    <el-option
                      v-for="item in warehousesList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item
                  label="盘点编号"
                  prop="check_no"
                  :label-width="formLabelWidth"
                >
                  <el-input :disabled="true" v-model="checkForm.check_no" />
                </el-form-item>
              </el-col>
              <!-- <el-col :span="8">
                <el-form-item
                  label="盘点员"
                  prop="check_user_id"
                  :label-width="formLabelWidth"
                >
               
                  <el-select
                    v-model="checkForm.check_user_id"
                    style="width: 100%"
                    placeholder="请选择盘点员"
                    clearable
                  >
                    <el-option
                      v-for="item in userList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item
                  label="盘点备注"
                  prop="remark"
                  :label-width="formLabelWidth"
                >
                  <el-input v-model="checkForm.remark"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row style="margin:0 30px" :gutter="10">
              <el-col :span="3">
                <el-button type="primary" @click="choseCommo()"
                  >商品选择</el-button
                >
              </el-col>
              <el-col :span="3">
                <el-button @click="importCheckSheet()">导入盘点</el-button>
              </el-col>
              <el-col :span="3">
                <el-button @click="addToList('all')">全仓盘点</el-button>
              </el-col>
              <el-col :span="3">
                <el-button @click="clearCheckList()">清空盘点</el-button>
              </el-col>
              <el-col :span="3">
                <el-button type="warning" @click="dialogLog.state = true"
                  >查看日志</el-button
                >
              </el-col>
            </el-row>
            <div class="check">
              <el-table
                height="400"
                class="check-table"
                :data="checkForm.data"
                border
              >
                <el-table-column prop="img" label="图片" width="60">
                  <template slot-scope="scope">
                    <el-image
                      :src="scope.row.img"
                      style="width: 30px"
                    ></el-image>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="numbering"
                  width="120"
                  label="商品编号"
                ></el-table-column>
                <el-table-column prop="name" label="商品名称"></el-table-column>

                <el-table-column
                  label="规格"
                  prop="spec_name"
                ></el-table-column>

                <!-- <el-table-column label="SKU条码"></el-table-column> -->
                <el-table-column prop="quantity" label="库存"></el-table-column>
                <el-table-column label="*盘点数量">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'data.' + scope.$index + '.check_num'"
                      :rules="checkRules.check_num"
                    >
                      <el-input
                        v-model="scope.row.check_num"
                        size="mini"
                        min="1"
                        type="number"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
              <!-- <el-pagination
            background
            layout="sizes,prev, pager, next"
            :total="tableData.length"
            :page-size="pageSize"
            @current-change="currentChange"
            @size-change="handleSizeChange"
            :page-sizes="[1,3,6]"
            :current-page="currentPage"
          >
          </el-pagination> -->
            </div>
          </el-form>
          <div class="bottom">
            <el-button type="success" @click="addCheck">完成盘点</el-button>
          </div>
        </div>
      </div>
    </el-card>

    <el-dialog
      title="导入盘点"
      :visible.sync="impDialogVisible"
      :before-close="handleChangeClose"
      width="30%"
      v-dialogDrag
    >
      <div>
        请先下载盘点导入模板:
        <span @click="downloadtemplate" class="downloadtemplate">下载模板</span>
      </div>
      <div>导入说明：</div>
      <div>1、支持按商品编号、商品名称、SKU条码中的任意一项或多项进行导入</div>
      <el-upload
        class="imp-upload"
        drag
        :limit="1"
        :on-exceed="exceed"
        :headers="token"
        :on-success="imgSuccess"
        :file-list="fileList"
        :on-remove="imgRemove"
        accept=".xls,.xlsx"
        action="https://erp.campgrounds.cn/api/fileupload/fileUpload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件</div>
      </el-upload>
    </el-dialog>
    <el-dialog
      :visible.sync="choseCommoDialogVisible"
      :before-close="handleChangeClose1"
      v-dialogDrag
      title="商品选择"
    >
      <div>
        当前盘点仓库：<span style="color:red">{{ warehouse }}</span>
      </div>
      <div class="chose-commo-table">
        <el-table
          :row-style="{ height: '30px' }"
          :cell-style="{ padding: '2px' }"
          style="font-size: 12px"
          v-loading="tableLoading"
          @selection-change="handleCheckedChange"
          :data="commoList"
          class="commo-table"
          :row-key="getRowKeys"
        >
          <el-table-column
            :reserve-selection="true"
            type="selection"
            width="80"
          >
          </el-table-column>
          <el-table-column prop="img" label="图片" width="60">
            <template slot-scope="scope">
              <el-image :src="scope.row.img" style="width: 30px"></el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="numbering"
            width="120"
            label="商品编号"
          ></el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column label="规格" prop="spec_name"></el-table-column>

          <!-- <el-table-column label="SKU条码"></el-table-column> -->
          <el-table-column prop="quantity" label="库存"></el-table-column>
        </el-table>
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :page-sizes="[7, 15, 20]"
          layout="total, sizes, prev, pager, next"
          :page-size="this.getInfo.limit"
          background
          :total="exp.num"
        >
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeChoseDialog()">取 消</el-button>
        <!-- <el-button type="primary" @click="setAdd" v-show="openState"
          >查 询</el-button
        > -->
        <el-button type="primary" @click="addToList('chose')">确 定</el-button>
      </div>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getWarehousesList, getUserlist } from '@/api/psi/storage.js'
import {
  getNewCheckNo,
  downloadModel,
  addCheck,
  getGoodsListexport,
} from '@/api/psi/check.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dialogLog: {
        state: false,
      },
      checkTableData: [],
      //当前页码
      getInfo: {
        warehouse_id: '',
        page: 1,
        page_code: 1,
        page_size: 7,
        limit: 5,
        page_num: 0,
        tag: 2,
      },
      exp: {
        // 总条数
        num: 0,
        // 页数
        count: '',
      },
      fileList: [],
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      userList: [],
      formLabelWidth: '120px',
      checkForm: {
        warehouse_id: '',
        check_no: 'can not get check number',
        // check_user_id: '',
        remark: '',
        data: [],
      },
      warehouse: '暂无仓库',
      checksheet: {},
      warehousesList: [],
      checkRules: {
        check_num: [
          { required: true, message: '请填写盘点数量', trigger: 'blur' },
        ],
        warehouse_id: [
          { required: true, message: '请选择盘点仓库', trigger: 'blur' },
        ],
        // check_user_id: [
        //   { required: true, message: '请选择盘点员', trigger: 'blur' },
        // ],

        check_no: [
          { required: true, message: '请输入出库编号', trigger: 'blur' },
        ],
        seller_id: [
          { required: true, message: '请选择销售员', trigger: 'change' },
        ],
      },
      impDialogVisible: false,
      choseCommoDialogVisible: false,
      commoList: [],
      tableLoading: false,
      checkedList: [],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    'checkForm.warehouse_id': {
      handler(newval) {
        if (typeof newval === 'number') {
          this.warehousesList.forEach((item) => {
            if (item.id === newval) {
              console.log('found')
              console.log(item.name)
              this.warehouse = item.name
            }
          })
        }
      },
    },
  },
  //方法集合
  methods: {
    async getSelectOtps() {
      // 仓库列表
      const { data: res1 } = await getWarehousesList()

      this.warehousesList = res1.data
      // 盘点员
      const { data: res2 } = await getUserlist()
      this.userList = res2.data
      // 盘点编号
      const { data: res3 } = await getNewCheckNo()
      this.checkForm.check_no = res3.data
    },
    async getCommo() {
      this.tableLoading = true
      this.getInfo.warehouse_id = this.checkForm.warehouse_id
      const { data: res } = await getGoodsListexport(this.getInfo)
      // console.log(res)
      this.commoList = res.data
      this.exp.num = res.exp.num
      this.getInfo.page_size = res.exp.count
      this.tableLoading = false
    },
    async choseCommo() {
      if (this.checkForm.warehouse_id === '') {
        this.$message.warning('请选择仓库！')
        return
      }

      console.log('valid')
      this.choseCommoDialogVisible = true
      this.getCommo()
    },

    // 导入盘点单
    importCheckSheet() {
      this.impDialogVisible = true
    },
    exceed() {
      this.$message({
        message: '只能上传一个文件',
        type: 'warning',
      })
    },
    // 下载模板
    async downloadtemplate() {
      const res = await downloadModel()
      console.log(res)
    },
    imgSuccess(response, file, fileList) {
      this.checksheet.url = response.data.url
    },
    imgRemove(file, fileList) {
      this.checksheet.url = ''
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          //  this.catalogue()
          this.impDialogVisible = false
          done()
        })
        .catch((_) => {})
    },
    closeImpDialog() {
      this.impDialogVisible = false
    },
    closeChoseDialog() {
      this.choseCommoDialogVisible = false
      this.checkedList = []
    },
    handleChangeClose1(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          //this.choseCommoDialogVisible = false
          //  this.catalogue()
          this.closeChoseDialog()
          done()
        })
        .catch((_) => {})
    },
    // 全仓盘点
    fullCheck() {},
    async addToList(mode) {
      if (mode === 'chose') {
        // this.checkTableData = this.checkedList
        this.checkForm.data = this.checkedList
        this.closeChoseDialog()
      } else if (mode === 'all') {
        if (this.checkForm.warehouse_id === '') {
          this.$message.warning('请选择仓库！')
          return
        }
        this.tableLoading = true
        const { data: res } = await getGoodsListexport({
          warehouse_id: this.checkForm.warehouse_id,
          tag: 1,
        })

        this.checkForm.data = res.data
        this.tableLoading = false
      }
    },
    // 处理表格 勾选事件
    handleCheckedChange(val) {
      this.checkedList = val
    },
    //修改每页数量
    sizeChange(value) {
      //console.log(value)
      this.getInfo.limit = value
      this.getCommo()
    },
    //分页切换
    pageChange(value) {
      //console.log(value)
      this.getInfo.page_code = value
      this.getCommo()
    },
    getRowKeys(row) {
      return row.id
    },
    // 清空盘点
    clearCheckList() {
      if (this.checkForm.data && this.checkForm.data.length !== 0) {
        this.checkForm.data = []
      } else {
        this.$message.warning('盘点列表为空')
      }
    },
    // 完成盘点
    addCheck() {
      this.$refs.checkFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.warning('请规范填写必填项')
          return
        } else {
          if (this.checkForm.data && this.checkForm.data.length !== 0) {
            this.checkForm.data.forEach((item) => {
              item.spec_id = item.goods_spec_id
            })
            this.checkForm.data.forEach((item) => {
              delete item['goods_spec_id']
              delete item['id']
              delete item['img']
              delete item['name']
              delete item['numbering']
              delete item['product_place']
              delete item['quantity']
              delete item['spec_name']
              delete item['v_id']
              delete item['vendor_name']
            })
            const json = JSON.stringify(this.checkForm.data)
            const { data: res } = await addCheck({
              data: json,
              warehouse_id: this.checkForm.warehouse_id,
              check_no: this.checkForm.check_no,
              remark: this.checkForm.remark,
            })

            if (res.code === 1) {
              this.$message.success(res.message)
              this.checkForm = {
                warehouse_id: '',
                check_no: 'can not get check number',
                // check_user_id: '',
                remark: '',
                data: [],
              }
              this.getSelectOtps()
            } else {
              this.$message.error(res.message)
              this.checkForm.data = []
            }
          } else {
            this.$message.warning('请至少选择一个商品')
            return
          }
        }
      })
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getSelectOtps()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.imp-upload {
  margin-top: 20px !important;
}
.downloadtemplate {
  color: blue;
  cursor: pointer;
}
.chose-commo-table {
  margin-top: 20px;
}

.commo-table >>> .has-gutter .el-checkbox .el-checkbox__inner {
  display: none;
}
.commo-table >>> .el-table {
  font-size: 14px !important;
  font-weight: normal !important;
}
.check {
  margin-top: 20px;
  margin-left: 35px;
  margin-right: 30px;
}
.check-table >>> .el-form-item {
  margin-bottom: 0 !important;
}
.user {
  position: relative;
}
.user .bottom {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.chose-commo-table >>> .el-checkbox {
  zoom: 150% !important;
}
</style>
